// Importando funciones de funciones.js
import { 
    apertura, llenarCardsBlog, llenarPageBlog, mostrarDetallesBlog, getParameterByName, iniciaSlider,
    navBackground, next_fondo, actBanner, nextBanner, updateActiveClass, llena_bannerspub, updateMessage, cycleMessages,
    reproduce_video_youtube, reproduce_video_popup, cursosNivel, mostrarCurso, registrarEventoEnLaApiMetaAds,
    throttle, llenarPagePatros, mostrarDetallesPatro
} from './funciones.js';    

(function() {

    'use strict';


    let heros = [];      

    // Obtener todos los enlaces del menú en un array
    const menuLinks = Array.from(document.querySelectorAll('.nav_links'));    

    const messages = [
        {title: "ADMINISTRACIÓN"},
        {title: "INVENTARIOS"},
        {title: "NEGOCIACIÓN CON PROVEEDORES"}, 
        {title: "SEGURIDAD ALIMENTARIA"},
        {title: "MEJORA LA EXPERIENCIA DEL CLIENTE"},
        {title: "CREACIÓN DE PROGRAMAS DE LEALTAD"},        
        {title: "IMPLEMENTA EL SERVICIO A DOMICILIO"},        
        {title: "ESTRATEGIAS DE PRECIOS"},        
        {title: "COMUNICACIÓN Y ATENCIÓN AL CLIENTE"},        
        {title: "MARKETING"},        
        {title: "FINANZAS"},        
        {title: "ASPECTOS LEGALES Y FISCALES"},
        {title: "TECNOLOGÍA"},        
        {title: "INTELIGENCIA EN LOS NEGOCIOS"},         
        {title: "FORMACIÓN Y DESARROLLO DE EQUIPOS"},
        {title: "DESARROLLO Y FORMACIÓN DEL PERSONAL"},
        {title: "VENTAS Y RETENCIÓN DE CLIENTES"}, 
        {title: "GESTIÓN DE LA REPUTACIÓN EN LÍNEA"},        
        {title: "REDES SOCIALES"},        
        {title: "BRANDING"},        
        {title: "Y MUCHOS CURSOS MÁS"}
    ];

    let currentIndex = 0;
    const titleElement = document.getElementById('message-title');
    const descriptionElement = document.getElementById('message-description');
    const containerElement = document.getElementById('message-container');
    const indicators = document.querySelectorAll('#indicators span');


    document.addEventListener('DOMContentLoaded', function(){

        //! ------------------------------------------------------        
        // * MENU LATERAL EN VERSIONES TABLET Y MOVIL
        //! ------------------------------------------------------        

        const botMenu = document.getElementById('botMenu');

        if (botMenu) {
            botMenu.addEventListener('click', function(event) {
                event.preventDefault();  // Evitar que la página se mueva al inicio
                apertura();  // Llamar a la función para abrir el menú
            });
        }

        //! ------------------------------------------------------        
        // * CÓDIGO PARA QUE CUANDO SE DE CLICK EN UN ENLACE DEL MENU CUANDO ES VERSIÓN MÓVIL, SE CIERRE.
        //! ------------------------------------------------------        

        const menu = document.getElementById('navPrincipal');
        const enlacesMenu = document.querySelectorAll('#navPrincipal .nav_links');
        
        enlacesMenu.forEach((enlace) => {
            enlace.addEventListener('click', () => {
                // Verifica si el menú tiene la clase 'menu2'
                if (menu.classList.contains('menu2')) {
                    // Si la tiene, la eliminamos
                    menu.classList.remove('menu2');
                }
            });
        });    

        //! ------------------------------------------------------                
        // *  LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA PRINCIPAL
        //! ------------------------------------------------------        

        const div_blog = document.getElementById('contenedor-cards-blog');

        if (div_blog !== null) {
            llenarCardsBlog(div_blog);  
        };

        //! ------------------------------------------------------                
        // * LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA DEL BLOG
        //! ------------------------------------------------------        

        const page_blog = document.getElementById('pagina-cards-blog');

        if (page_blog !== null) {
            llenarPageBlog(page_blog);  
        };

        //! ------------------------------------------------------                
        // * LLENAR CARDS DE LOS PROVEEDORES Y PATROCINADORES
        //! ------------------------------------------------------        

        const page_patros = document.getElementById('pagina-patrocinadores');

        if (page_patros !== null) {
            llenarPagePatros(page_patros);  
        };

        //! ------------------------------------------------------        
        // * Inicializa los slider de la página
        //! ------------------------------------------------------        

        iniciaSlider();

        //! ------------------------------------------------------        
        // * Llena la información de una noticia del BLOG
        //! ------------------------------------------------------        

        const blogId = getParameterByName('id'); 
        if (blogId !== null) {
            mostrarDetallesBlog(blogId);
        }

        //! ------------------------------------------------------                
        // * Función para mostrar los detalles de un curso
        //! ------------------------------------------------------        

        const cursoId = getParameterByName('idc'); 
        if (cursoId !== null) { 
            mostrarCurso(cursoId);   

            // Registrando el evento en la API de Conversiones de Meta Ads
            registrarEventoEnLaApiMetaAds("ViewContent", null, null, null)
                .catch(error => {
                    console.error("Hubo un error al enviar el evento a Meta Ads:", error.message);
                }); 
        }

        //! ------------------------------------------------------                
        // * REGISTRAR EVENTO ViewContent EN LA API DE FACEBOOK
        //! ------------------------------------------------------        

        const infoComunidad = document.getElementById('info-comunidad');

        if (infoComunidad !== null) {

            // Registrando el evento en la API de Conversiones de Meta Ads
            registrarEventoEnLaApiMetaAds("ViewContent", null, null, null)
                .catch(error => {
                    console.error("Hubo un error al enviar el evento ViewContent a Meta Ads al consultar la información de una comunidad:", error.message);
                });

        };

        //! ------------------------------------------------------        
        // * Muestra los detalles de un patrocinador
        //! ------------------------------------------------------        

        const patrosId = getParameterByName('idp'); 
        if (patrosId !== null) {
            mostrarDetallesPatro(patrosId);
        }

        //! ------------------------------------------------------        
        // * Carga el evento para aclarar u obscurecer el background del NAV
        // * También marca la opción de menú en la que se encuentra el desplazamiento
        //! ------------------------------------------------------                
        
        window.addEventListener('scroll', throttle(() => {
            navBackground();
            updateActiveClass(menuLinks);
        }, 100));        

        navBackground();

        //! ------------------------------------------------------        
        // * Actualizar la clase 'nav_active' cuando la página se carga
        //! ------------------------------------------------------        

        window.addEventListener('load', () => updateActiveClass(menuLinks));        

        //! ------------------------------------------------------        
        // * Activa la función para hacer dinámico el fondo del hero de la pantalla, para cambiar entre cada imagen.
        //! ------------------------------------------------------                

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(() => next_fondo(heros), 3000);
        }

        //! ------------------------------------------------------        
        // * Visualizando los banners de publicidad
        //! ------------------------------------------------------        

        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) { 
            llena_bannerspub(div_pub);         
        };

        //! ------------------------------------------------------                
        //* Visualizando mensajes informativos en la banda de color del hero
        //! ------------------------------------------------------        

        if (containerElement !== null) {
            updateMessage(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
            cycleMessages(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
        }

        //! ------------------------------------------------------        
        // * Monitoreando los enlaces del botón PLAY para reproducir vídeos de Youtube en el DOM
        //! ------------------------------------------------------                

        const playLink = document.querySelector('.play');
        if (playLink) {
            document.querySelectorAll('.play').forEach(link => {
                link.addEventListener('click', reproduce_video_youtube);
            }); 
        }

        //! ------------------------------------------------------
        //* Reproduce un vídeo de Youtube en una Ventana Popup
        //! ------------------------------------------------------        

        if ($('btn-play').length && $('#ventanaVideo').length && $('#iframeVideo').length) {
            

            $('ul.slide-area').on('click', 'btn-play', function(event) { 
                event.preventDefault();

                reproduce_video_popup(this);
            });
    
            $('#ventanaVideo').on('hidden.bs.modal', function () {
                $('#iframeVideo').attr('src', '');
            }); 
        }

        //! ------------------------------------------------------
        //* Funcionalidad para abrir y cerrar las Pregunta Frecuentes
        //! ------------------------------------------------------        

        // Asegurar que todos los paneles están correctamente inicializados
        $('.accordion-content').hide(); // Oculta todos los paneles
        $('.accordion-button').attr('aria-expanded', 'false'); // Establece todos como no expandidos

        $('.accordion-button').on('click', function() {
            var isExpanded = $(this).attr('aria-expanded') === 'true';
            $(this).attr('aria-expanded', !isExpanded);
            $(this).next('.accordion-content').slideToggle();

            // Cierra los otros elementos si solo uno debe estar abierto a la vez
            if (!isExpanded) {
                $('.accordion-content').not($(this).next()).slideUp();
                $('.accordion-button').not(this).attr('aria-expanded', 'false');
            }
        });

        //! ------------------------------------------------------
        //* Mantiene actualizado el año en la línea de los Derechos de Autor (PIE DE PÁGINA)
        //! ------------------------------------------------------

        document.addEventListener('contentIncluded', () => {
            const year = new Date().getFullYear(); // Obtiene el año actual
            const yearElement = document.getElementById('current-year');
            if (yearElement) {
                yearElement.textContent = year; // Establece el año en el elemento span
            }
        });

        //! ------------------------------------------------------
        //* CÓDIGO PARA FILTRAR LOS CURSOS DEL NIVEL PROFESIONAL DESDE LA PANTALLA PRINCIPAL
        //! ------------------------------------------------------        

        const botCursosPro = document.getElementById('botCursosPro');

        if (botCursosPro !== null) {
            botCursosPro.addEventListener('click', (event) => {            
                localStorage.setItem("nivelSeleccionado", 2); 
            });
        };

        //! ------------------------------------------------------
        //* CÓDIGO PARA FILTRAR LOS CURSOS DEL NIVEL MAESTRO DESDE LA PANTALLA PRINCIPAL
        //! ------------------------------------------------------        
        const botCursosMaestro = document.getElementById('botCursosMaestro');

        if (botCursosMaestro !== null) {
            botCursosMaestro.addEventListener('click', (event) => {            
                localStorage.setItem("nivelSeleccionado", 3); 
            });
        };

        //! ------------------------------------------------------
        //* CÓDIGO PARA LA PÁGINA DE LOS CURSOS 
        //* PARA DETECTAR EL FILTRO PARA MOSTRAR LOS CURSOS SEGÚN EL NIVEL SELECCIONADO
        //! ------------------------------------------------------

        const secCursos = document.getElementById('secCursos');

        if (secCursos !== null) {
            
            cursosNivel();    

        } else {

            const secDetalleCurso = document.getElementById('fondoCurso');            
            if (secDetalleCurso === null) {            
                localStorage.removeItem("nivelSeleccionado");
            }

        };
        

        //! ------------------------------------------------------
        //* Enviando el formulario de contacto
        //* Validado con Google reCaptcha        
        //! ------------------------------------------------------

        const contactForm = document.getElementById('contact-form');

        if(contactForm) {

            document.getElementById("contact-form").addEventListener("submit", function(event) {
                event.preventDefault();
            
                const token = grecaptcha.getResponse(); // Obtiene el token del reCAPTCHA
                if (!token) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Por favor, verifica que no eres un robot.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar'
                    });
                    return;
                }
            
                fetch('/scripts/verify-recaptcha.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams({ token: token })
                })
                .then(response => response.json())
                .then(data => {

                    if (data.success) {

                        // console.log('Validación exitosa:', data);

                        // Captura los valores de los campos del formulario

                        const userName = document.getElementById("user_name").value;
                        const userPhone = document.getElementById("user_phone").value;
                        const userEmail = document.getElementById("user_email").value;
                        const userBusiness = document.getElementById("user_business").value;
                        const userMessage = document.getElementById("user_message").value;

                        // Configura los parámetros para enviar con EmailJS

                        const templateParams = {
                            user_name: userName,
                            user_phone: userPhone,
                            user_email: userEmail,
                            user_business: userBusiness,
                            user_message: userMessage
                        };

                        // Usa EmailJS para enviar el correo
                        emailjs.send("service_5apmar9", "template_169bidh", templateParams)                        
                        .then(function(response) {

                            Swal.fire({
                                title: 'Correo Envíado',
                                text: 'Tu correo fue envíado correctamente. En breve recibirás noticias.',
                                icon: 'success',
                                confirmButtonText: 'Cerrar'
                            });

                            document.getElementById("contact-form").reset(); 
                            window.location.href = 'graciasporcontactarnos.html';


                        }, function(error) {

                            Swal.fire({
                                title: 'Error',
                                text: 'Hubo un error al enviar el mensaje. Inténtalo nuevamente.',
                                icon: 'error',
                                confirmButtonText: 'Cerrar'
                            });

                            console.error("Error:", error);

                        });

                    } else {
                        Swal.fire({
                            title: 'Error en la verificación',
                            text: data.message || 'No se pudo verificar tu solicitud. Inténtalo nuevamente.',
                            icon: 'error',
                            confirmButtonText: 'Cerrar'
                        });
                        console.error('Detalles:', data);
                    }
                })
                .catch(error => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al validar el reCAPTCHA. Inténtalo nuevamente.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar'
                    });
                    console.error('Error en la solicitud:', error);
                });
            });
                        
        }

        //! ------------------------------------------------------
        //* Registrando el evento "Contact" en la API de Meta Ads
        //! ------------------------------------------------------

        const botWP = document.getElementById('enlaceWP');        

        if(botWP) {
            document.getElementById("enlaceWP").addEventListener("click", function (event) {

                event.preventDefault(); 

                registrarEventoEnLaApiMetaAds("Contact", null, null, null)
                .then(() => {
                    console.log('Registrando evento Contact en la API de Meta Ads');                
                    window.open(this.href, "_blank");                
                })
                .catch(error => {
                    console.error("Error al enviar evento a la API:", error);
                    window.open(this.href, "_blank");
                });
                

            });
        }

        //! ------------------------------------------------------
        //* Registrando el evento "Lead" en la API de Meta Ads
        //! ------------------------------------------------------

        const botLead = document.getElementById('boton-lead');

        if (botLead) {

            document.getElementById("boton-lead").addEventListener("click", function (event) {

                event.preventDefault(); 

                registrarEventoEnLaApiMetaAds("Lead", null, null, null)
                .then(() => {
                    console.log('Registrando evento Lead en la API de Meta Ads');                
                    window.open(this.href, "_blank");                
                })
                .catch(error => {
                    console.error("Error al enviar evento a la API:", error);
                    window.open(this.href, "_blank");
                });

            });
        }


        //! ------------------------------------------------------
        //* Registrando el evento "Inciar Pago" en la API de Meta Ads
        //! ------------------------------------------------------

        const botPago = document.querySelector(".boton-pago")

        if (botPago) {

            document.querySelectorAll(".boton-pago").forEach(boton => {

                boton.addEventListener("click", function (event) {

                    event.preventDefault(); // Evita la redirección inmediata
            
                    let urlDestino = this.href; // Capturamos la URL de destino del botón
            
                    console.log('Registrando evento InitiateCheckout en la API de Meta Ads');
            
                    registrarEventoEnLaApiMetaAds("InitiateCheckout", null, null, null)
                        .then(() => {
                            window.open(urlDestino, "_blank");                            
                        })
                        .catch(error => {
                            console.error("Error al enviar evento a la API:", error);
                            window.open(urlDestino, "_blank");                            
                        });
                });
            });

        }

        //! ------------------------------------------------------
        //* Registrando el evento "Lead" en la API de Meta Ads
        //! ------------------------------------------------------

        const divCompra = document.getElementById('compra-realizada');

        if (divCompra) {

            console.log('Registrando evento Purchase en la API de Meta Ads');

            registrarEventoEnLaApiMetaAds("Purchase", null, null, null);

        }


    });
    
})();